const dev = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.picsildev.com"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_mXvxdA7vc",
    APP_CLIENT_ID: "5u5e775smkodtaqhr56fk0c1n3",
    IDENTITY_POOL_ID: "us-east-2:5495d541-7309-4b27-9158-f80ca47d880b"
  }
};

const prod = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.picsilcloud.com"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_r9jFdDX7l",
    APP_CLIENT_ID: "4ki3t7llratbm4d74h3jp9fjc",
    IDENTITY_POOL_ID: "us-east-2:3a5d07f5-12ef-4733-93c9-67e9393f1273"
  }
};

const config = process.env.APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};
