import React, { Component } from "react";
import { ArcGauge } from '@progress/kendo-react-gauges';
import "./Admin.css";

export default class Admin extends Component {
  render() {
    return (
      <div className="Admin">
        <div className="lander">
          <h1>picsil admin</h1>
          <p>peace of mind.</p>
        </div>
      </div>
    );
  }
}
