import React, { Component } from "react";
import { ArcGauge } from '@progress/kendo-react-gauges';
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>picsil cloud</h1>
          <p>peace of mind.</p>
        </div>
      </div>
    );
  }
}
